section.intro {
  position: relative;
  background-color: rgba(248, 243, 230, 1);
  font-family: "Noto Sans Kr", sans-serif;

  article {
    display: flex;
    flex-direction: column;
    height: 100%;

    .wrap {
      // border: 1px solid darkgoldenrod;
      flex: 1;

      &.name {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .box {
          text-align: center;
          color: rgba(255, 33, 144, 1);
          margin-bottom: 60px;

          figure {
            display: flex;
            align-items: center;

            img {
              width: 100px;
            }

            figcaption {
              font-size: 30px;
              font-weight: bolder;
              margin-left: 10px;
              margin-top: -6px;
            }
          }

          .dnart {
            margin: 2em 0 0;

            img {
              width: 130px;
              height: 65.53px;
              margin-bottom: 0.8em;
            }

            .d-and-art {
              display: block;
              font-size: 1.5em;
              // color: rgb(29, 29, 31);
              color: rgb(0, 128, 1);

              span {
                display: inline-block;
                opacity: 0.4;
                // padding: 0 0.4em;
              }
            }
          }

          @media (min-width: 768px) {
            & {
              margin-bottom: 100px;

              figure {
                img {
                  width: 150px;
                }

                figcaption {
                  font-size: 50px;
                }
              }

              p {
                font-size: 1.2rem;
              }

              .dnart {
                img {
                  width: 160px;
                  height: 80.63px;
                }
              }
            }
          }

          @media (min-width: 1400px) {
            & {
              figure {
                img {
                  width: 200px;
                }

                figcaption {
                  font-size: 60px;
                }
              }

              p {
                font-size: 1.2rem;
              }
            }
          }
        }
      }

      &.flag {
        display: flex;
        justify-content: center;

        .box {
          width: 90%;
          max-width: 1000px;
          display: flex;
          justify-content: space-around;

          figure {
            width: 140px;

            @media (min-width: 768px) {
              width: 250px;
            }

            @media (min-width: 1400px) {
              width: 320px;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
