html {
    height: 100%;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

body {
    width: 100vw;
    min-height: 100%;
    color: rgba(0, 0, 0, 0.65);
    // background-color: rgba(0, 0, 0, .08);
    // overflow: hidden;
}

* {
    outline: none;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

label {
    color: rgba(0, 0, 0, .54);
}

input,
textarea {
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(112, 112, 112, 0.4);
        opacity: 1;
        font-size: .9rem;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(112, 112, 112, 0.4);
        font-size: .9rem;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(112, 112, 112, 0.4);
        font-size: .9rem;
    }
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 19px;
}

h5 {
    font-size: 13px;
}

h6 {
    font-size: 11px;
}

b {
    font-weight: bold;
}

strong {
    font-weight: bold;
}

small {
    font-size: smaller;
}

sub {
    vertical-align: sub;
    font-size: smaller;
}

img {
    vertical-align: bottom;
}