section.about-us {
  position: relative;
  // @include sectionContainer();

  article.introduce {
    position: relative;
    min-height: 100vh;
    background-image: url("/assets/images/profile-bg.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width: 1024px) {
      & {
        padding-top: 80px;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      height: 90%;
      padding: 2rem 1rem;
      background-color: rgba(0, 0, 0, 0.5);
      color: #ffffff;

      @media (min-width: 768px) {
        & {
          padding: 2rem 3.5rem;
        }
      }

      @media (min-width: 1024px) {
        & {
          padding: 3rem 7rem;
        }
      }

      @media (min-width: 1400px) {
        & {
          width: 80%;
          padding: 5rem 15rem;
        }
      }

      .contents {
        h3,
        .academy,
        .before,
        .now {
          margin-bottom: 1.5rem;
          line-height: 1.5;
        }

        .before {
          p {
            &.white-space {
              @media (min-width: 1366px) {
                white-space: nowrap;
              }
            }

            span.comment {
              display: block;
              font-size: 0.8rem;
              margin-left: 0.5rem;

              @media (min-width: 1024px) {
                & {
                  display: initial;
                }
              }
            }
          }
        }
      }

      .divide-line {
        width: 100%;
        border-top: 1px solid white;
        margin: 3rem 0;
      }

      .greet {
        p {
          line-height: 1.8;
          margin: 2rem 0 0.5rem;
        }
      }
    }
  }

  article.history {
    font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    background-color: rgba(248, 243, 230, 1);

    .wrap {
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      padding: 0 1rem;

      .profile {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding: 3rem 0;

        @media (min-width: 768px) {
          & {
            font-size: 24px;
            padding: 3rem 0 4rem;
          }
        }

        @media (min-width: 1024px) {
          & {
            padding: 5rem 0;
          }
        }

        @media (min-width: 1400px) {
          & {
            padding: 5rem 0 8rem;
          }
        }

        .line {
          border: 1px solid rgba(0, 0, 0, 0.25);
          width: 100%;
          height: 1px;
          margin-left: 1.3rem;
        }
      }

      .korea--concert,
      .italy--concert {
        position: relative;
        line-height: 1.5;

        @media (min-width: 1400px) {
          & {
            max-width: 900px;
            margin: 0 auto;
          }
        }

        h3.title {
          width: 100%;
          padding: 0 0 1.5rem;

          @media (min-width: 768px) {
            & {
              position: absolute;
              font-size: 40px;
              margin-top: -30px;
            }
          }

          @media (min-width: 1400px) {
            & {
              width: fit-content;
              margin-top: -35px;
              margin-left: -30px;

              span {
                display: block;
              }
            }
          }
        }

        ul.contents {
          background-color: rgba(249, 236, 217, 1);
          padding: 1rem;

          @media (min-width: 768px) {
            & {
              padding: 5rem 7rem;
            }
          }

          li {
            margin-bottom: 1.5rem;

            strong {
              display: block;
              margin-bottom: 0.3rem;
            }

            small {
              display: block;
            }

            @media (min-width: 768px) {
              & {
                br.mobile {
                  display: none;
                }
              }
            }
          }
        }
      }

      .korea--concert {
        margin-top: 3rem;
        padding-bottom: 4rem;

        @media (min-width: 768px) {
          & {
            text-align: right;
            margin-top: 5rem;
          }
        }

        @media (min-width: 1400px) {
          & {
            margin-top: 8rem;
            padding-bottom: 5rem;

            h3.title {
              right: 0;
              margin-right: 1.2rem;
            }
          }
        }
      }

      .italy--concert {
        p {
          margin-bottom: 0.3rem;

          span {
            font-weight: bolder;
            font-size: smaller;
            padding-left: 0.5rem;
          }
        }
      }
    }
  }

  article.contact {
    background-color: rgba(248, 243, 230, 1);

    .wrap {
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      padding: 0 1rem 3rem;

      @media (min-width: 768px) {
        & {
          padding: 0 1rem 4rem;
        }
      }

      @media (min-width: 1024px) {
        & {
          padding: 0 1rem 5rem;
        }
      }

      .division {
        display: flex;
        align-items: center;

        span {
          font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;
          font-size: 1rem;

          @media (min-width: 768px) {
            & {
              font-size: 24px;
            }
          }
        }

        .line {
          border: 1px solid rgba(0, 0, 0, 0.25);
          width: 100%;
          height: 1px;
          margin-left: 1.3rem;
        }
      }

      .contents {
        padding: 1rem;
        line-height: 1.8;

        @media (min-width: 768px) {
          & {
            padding: 3rem;
          }
        }
      }
    }
  }
}
