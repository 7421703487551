.swiper-container {
    width: 100%;
    height: 100vh;

    .swiper-wrapper {

        .swiper-slide>section {
            padding-top: 60px;
            height: 100%;

            @media (min-width: 1024px) {
                & {
                    padding-top: 80px;
                }
            }
        }
    }

    .swiper-pagination-bullet {
        // background-color: rgba(52, 73, 94, .7);
    }

    .swiper-pagination-bullet-active {
        background-color: #fff;
        // background-color: rgba(52, 73, 94, .7);
    }
}