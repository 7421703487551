@mixin sectionContainer() {

    &.container {
        min-height: 100vh;
        background-color: rgba(247, 243, 230, 1);
        padding: 80px 1rem;

        @media (min-width: 768px) {
            & {
                padding: 110px 1rem;
            }
        }

        @media (min-width: 1400px) {
            & {
                padding: 130px 1rem;
            }
        }
    }
}

@mixin maxWrap($width) {
    .wrap {
        width: 100%;
        max-width: $width;
        margin: 0 auto;
    }
}