section.profile {
    background-image: url('/assets/images/profile-bg.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    color: #ffffff;

    .wrap {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        font-size: 1.1rem;
        line-height: 1.8;

        @media(min-width: 1400px) {
            & {
                top: 20%;
                left: 58%;
                transform: none;
            }
        }

        .name {
            font-weight: bolder;
        }

        .more {
            position: absolute;
            right: 0;
            padding: 1rem;
            line-height: 1.2;
            font-family: 'Noto Sans KR', sans-serif;
            font-size: .85rem;

            img {
                margin-bottom: -3px;
                padding-left: 5px;
                border-radius: 2px;
            }
        }
    }

    .footer {
        font-family: "Noto Sans Kr", sans-serif;
        position: absolute;
        bottom: 0;
        padding-right: 1rem;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #ff2190;
    }
}