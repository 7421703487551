section.represent {
    color: rgba(255, 255, 255, 1);

    &.hanji-fashion-2019 {
        background-color: rgb(218, 192, 40);
        color: rgba(255, 255, 255, 1);
    }

    &.hanji-2019 {
        background-color: rgb(115, 97, 83);
        color: rgba(255, 255, 255, 1);
    }

    &.conference {
        background-color: rgb(8, 81, 127);
        color: rgba(255, 255, 255, 1);
    }

    &.triennale-2014 {
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 1);
    }

    &.triennale-2013 {
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 1);
    }

    &.muhyoung {
        background-color: rgba(0, 0, 0, 1);
    }

    &.hanji-2011 {
        background-color: rgba(138, 123, 150, 1);
    }

    &.najeon {
        background-color: rgba(0, 26, 31, 1);
    }

    &.cheongja {
        background-color: rgba(242, 237, 228, 1);
        color: rgba(0, 0, 0, 1);
    }

    &.maedeup {
        background-color: rgba(0, 0, 0, 1);
    }

    article.wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        & > .image-box,
        & > .content-box {
            width: 100%;
        }

        .image-box {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            height: 80%;

            img {
                max-width: 80%;
                object-fit: contain;
            }
        }

        .content-box {
            position: relative;
            height: 20%;

            .mobile {
                text-align: center;
                font-size: 1.1rem;
                font-weight: bold;
                line-height: 1.5;
            }

            .desktop {
                display: none;
            }

            .more {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: 'Noto Sans KR', sans-serif;
                font-size: 0.85rem;

                img {
                    margin-bottom: -3px;
                    padding-left: 5px;
                    border-radius: 2px;
                }
            }
        }

        @media (min-width: 768px) {
            & {
                .image-box {
                    img {
                        max-width: 70%;
                    }
                }

                .content-box {
                    .mobile {
                        line-height: 5;
                    }
                }
            }
        }

        @media (min-width: 1024px) {
            & {
                flex-direction: row;

                & > .image-box,
                & > .content-box {
                    height: 100%;
                }

                .image-box {
                    &.muhyoung,
                    &.najeon {
                        order: 2;
                    }

                    img {
                        max-width: 70%;
                    }
                }

                .content-box {
                    .mobile {
                        display: none;
                    }

                    .desktop {
                        display: block;
                        position: absolute;
                        left: 20%;
                        bottom: 25%;

                        .title {
                            font-size: 1.5rem;
                            padding-bottom: 2rem;
                        }

                        .time,
                        .place {
                            letter-spacing: 30px;
                            padding-bottom: 0.5rem;
                            line-height: 1.5;

                            span {
                                letter-spacing: 0px;
                                // letter-spacing: initial;
                            }
                        }

                        .cooperation {
                            display: flex;
                            line-height: 1.5;

                            p {
                                display: inline;
                                min-width: 62px;
                            }

                            span {
                                display: inline-block;
                                // width: 350px;
                                word-break: keep-all;
                                padding-left: 30px;
                            }
                        }
                    }

                    .more {
                        // position: absolute;
                        top: 83%;
                        left: 77%;
                        // transform: translate(-50%, -50%);

                        img {
                            margin-bottom: -3px;
                            padding-left: 5px;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }

        @media (min-width: 1400px) {
            & {
                .image-box {
                    img {
                        width: 60%;
                    }
                }
            }
        }
    }
    &.hanji-fashion-2019,
    &.triennale-2014,
    &.triennale-2013 {
        article.wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            @media (min-width: 1024px) {
            }

            .image-box {
                height: 65%;

                img {
                    width: 100%;

                    @media (min-width: 1024px) {
                        & {
                            width: 70%;
                        }
                    }

                    @media (min-width: 1400px) {
                        & {
                            width: 40%;
                        }
                    }
                }
            }

            .content-box {
                height: 35%;

                .desktop {
                    width: fit-content;
                    bottom: 30%;
                    left: 50%;
                    transform: translateX(-50%);

                    @media (min-width: 1400px) {
                        & {
                            bottom: 45%;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            .more {
                @media (min-width: 1024px) {
                    top: 77%;
                }

                @media (min-width: 1400px) {
                    top: 63%;
                    left: 67%;
                }
            }
        }
    }

    &.hanji-fashion-2019,
    &.triennale-2013 {
        article.wrap {
            .image-box.triennale-2013,
            .image-box.hanji-fashion-2019 {
                height: 70%;

                img {
                    height: 90%;
                    object-fit: contain;
                }
            }

            .content-box {
                height: 30%;
            }
        }
    }
}
