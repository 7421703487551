section.italy,
section.korea {
    @include sectionContainer();
    @include maxWrap(1000px);
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    .wrap {
        display: flex;
        flex-direction: column;

        @media (min-width: 1400px) {
            flex-direction: row;
        }

        .title {
            font-size: 1.1rem;
            font-weight: bolder;
            padding-bottom: 1.5rem;

            @media (min-width: 1024px) and (max-width: 1399px) {
                & {
                    font-size: 1.3rem;
                    padding-left: 3.5rem;
                    padding-bottom: 3rem;
                }
            }

            @media (min-width: 1400px) {
                & {
                    font-size: 1.5rem;
                    margin-right: 5rem;
                }
            }

            span {
                @media (min-width: 1400px) {
                    & {
                        display: block;
                        padding-top: 10px;
                    }
                }
            }
        }

        ul.gallery {
            flex: 1;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 -1rem;

            li {
                width: 50%;
                display: flex;
                justify-content: center;
                padding-bottom: 5rem;

                &:last-child {
                    padding-bottom: 0;
                }

                @media (min-width: 768px) {
                    & {
                        width: 33.333333%;
                    }
                }

                figure {
                    width: 85%;
                    cursor: pointer;

                    a {
                        cursor: inherit;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                        object-fit: contain;

                        @media (min-width: 768px) {
                            & {
                                max-height: 250px;
                            }
                        }
                    }

                    figcaption {
                        word-break: keep-all;
                        text-align: center;
                        line-height: 1.3;
                        padding-top: 1rem;

                        span {
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}
