@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&subset=korean');
@import url('https://fonts.googleapis.com/css?family=Oswald:500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,900');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+KR:200,400,700,900');


body {
    font: {
        size: 16px;
        family: 'Noto Serif KR',
            'Oswald',
            'Roboto',
            Arial,
            Helvetica,
            sans-serif;
    }

    @include media(mobile-only) {
        & {
            font-size: 15px;
        }
    }
}