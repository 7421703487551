section.detail {
    @include sectionContainer();
    @include maxWrap(1200px);

    &.milano-2014 {
        figure {
            p {
                font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
                    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-size: 0.85rem;
                line-height: 1.8;
            }
        }
    }

    &.vocal,
    &.antonio {
        .description {
            margin-bottom: 3rem !important;

            @media (min-width: 1024px) {
                & {
                    margin-bottom: 5rem !important;
                }
            }

            h1 {
                font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
                    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-weight: bolder;
                font-size: 18px;
                line-height: 1.3;
                padding-top: 2rem;

                @media (min-width: 1024px) {
                    & {
                        font-size: 24px;
                    }
                }

                .italy {
                    padding-top: 0.3rem;
                    font-size: 17px;
                    font-style: italic;
                    font-weight: lighter;
                    opacity: 0.88;

                    @media (min-width: 1024px) {
                        font-size: 22px;
                    }
                }
            }
        }

        .masonry {
            .grid {
                .grid-item {
                    &.m-top {
                        margin-top: 5rem;
                    }
                }
            }
        }
    }

    &.roma,
    &.opera {
        .description {
            flex-direction: column !important;

            figure {
                img {
                    max-width: 100% !important;
                }
            }

            .contents {
                flex: none !important;
                justify-content: flex-start !important;
            }
        }
    }

    .wrap {
        @media (min-width: 1400px) {
            & {
                padding: 0 1rem;
            }
        }

        .description {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 5rem;

            @media (min-width: 1024px) {
                & {
                    flex-direction: row;
                    margin-bottom: 6rem;
                }
            }

            figure {
                position: relative;

                img {
                    width: 100%;
                    max-width: 500px;
                    display: block;
                    margin: 0 auto;
                }
            }

            .contents {
                position: relative;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: flex-end;

                .box {
                    max-width: 550px;
                    line-height: 1.5;
                    padding: 2rem 0 0;
                    // position: absolute;
                    bottom: 0;

                    &.max-none {
                        max-width: none;
                    }

                    .title {
                        font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI',
                            Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
                            sans-serif;
                        font-weight: bolder;
                        font-size: 1.1rem;
                        word-break: keep-all;
                        padding-bottom: 1rem;

                        @media (min-width: 768px) {
                            & {
                                font-size: 1.5rem;
                                padding-bottom: 2rem;

                                .italy {
                                    font-size: 1.4rem !important;
                                }
                            }
                        }

                        .italy {
                            // padding-top: 0.3rem;
                            font-size: 1rem;
                            font-style: italic;
                            font-weight: lighter;
                            opacity: 0.88;
                        }
                    }

                    .time,
                    .place {
                        letter-spacing: 30px;
                        padding-bottom: 0.5rem;
                        line-height: 1.5;

                        span {
                            letter-spacing: 0px;
                        }
                    }

                    .cooperation {
                        display: flex;
                        line-height: 1.5;

                        p {
                            display: inline;
                            min-width: 62px;
                        }

                        span {
                            display: inline-block;
                            // width: 350px;
                            word-break: keep-all;
                            padding-left: 30px;

                            br {
                                display: none;
                            }

                            @media (min-width: 768px) {
                                & {
                                    br {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .masonry {
            .grid-sizer,
            .grid-item {
                // width: 48.5%;
                width: 100%;

                @media (min-width: 1024px) {
                    & {
                        // width: 32.666%;
                        width: 49%;
                    }
                }

                &.grid-item--100 {
                    width: 100%;
                    margin: 10px 0;

                    h4 {
                        line-height: 1.5;
                    }
                }
            }

            .grid-item {
                margin-bottom: 17px;

                img {
                    width: 100%;
                    transition: 0.3s;
                    // border-radius: 8px;
                    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .5);
                    cursor: pointer;
                }

                p {
                    line-height: 1.6;
                    font-size: 0.85rem;
                    padding-top: 3px;
                }

                &.active {
                    position: fixed !important;
                    top: 0 !important;
                    left: 0 !important;
                    z-index: 998;
                    width: 100% !important;
                    height: 100vh !important;
                    background-color: rgba(0, 0, 0, 0.8) !important;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 90%;
                        max-width: 100%;
                        height: auto;
                        object-fit: contain;
                        // box-shadow: 0 1px 18px 0 rgba(0, 0, 0, .8);

                        @media (min-width: 1024px) {
                            & {
                                width: auto;
                                height: 70%;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
