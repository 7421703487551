footer {
    font-family: "Noto Sans Kr", sans-serif;
    position: relative;
    text-align: center;
    font-size: 14px;
    padding: 1rem;
    color: #fff;
    background-color: #ff2190;

    @media (min-width: 768px) {
        & {
            text-align: right;
        }
    }
}